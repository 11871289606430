import Proj1 from '../assets/MFPRand.png'
import Proj2 from '../assets/2DFlowPic.png' 
import Proj3 from '../assets/2DQSMotionPic.png'
import Proj4 from '../assets/EvoboxPic.png' 
import Proj5 from '../assets/FVPic.png'
import Proj6 from '../assets/TTSPic.png' 
import Proj7 from '../assets/SchedulerPic.png'


export const PersonalProjectList = [

    {
        name: "3D MFP Simulator",
        image: Proj1,
        text: "A 3D physics simulator of MFP robots. Robots of arbritary topology are supported. Both a decentralized and centralized controller are implemented. The simulator supports obstacles, random robot generation, and thruster and sensor faults, among other parameters.",
        skills: "C++, ODE",
        link: "https://github.com/MJDoyle/MHPODE",
    },

    {
        name: "2D MFP Fluid Flow simulator",
        image: Proj2,
        text: "A 2D simulator of fluid flow within an MFP robot. An equation system is created by analogy to an electric surcuit, and solved to determine fluid pressures and flow rates. Theoretical resultant motion of the robot is indicated.",
        skills: "C++, SFML",
        link: "https://github.com/MJDoyle/MFPFluidFlow2D",
    },

    {
        name: "2D MFP motion simulator",
        image: Proj3,
        text: "A 2D simulator of an MFP robot undergoing quasi-static motion (velocity proportional to thrust force). Robot configurations can be created on the fly, with quasi-static proportionality constants updated automatically. The relative motion of a goal point is calculated.",
        skills: "C#, Unity",
        link: "https://github.com/MJDoyle/MHPQS",
    },

    {
        name: "Evo-bot simulator",
        image: Proj4,
        text: "A 2D physics simulator of the Evo-bot simulator. Robotic organisms can connect, grow, gain energy from light sources within the environment, and reproduce. Additional modules types are implemented.",
        skills: "C++, SFML, Box2D",
        link: "https://github.com/MJDoyle/Evobox2D",
    },

    {
        name: "FUMBBL visualizer",
        image: Proj5,
        text: "A Unity app for use when streaming games of the online Fantasy Football client FUMBBL. The app captures the FUMBBL window and rearragnes the UI elements to provide a more condensed layout. Reserves boxes can be popped in and out.",
        skills: "C#, Unity",
        link: "https://github.com/MJDoyle/BBPitchVisualizer",
    },

    {
        name: "Twitch TTS bot",
        image: Proj6,
        text: "A text-to-speech bot that uses the Windows TTS API to vocalize messages provided by chatters when redeeming channel points. Also provides sound effects that can be activated by chat commands.",
        skills: "C++, SFML",
        link: "https://github.com/MJDoyle/TwitchTTSBot",
    },

    {
        name: "Scheduler bot",
        image: Proj7,
        text: "A simple scheduler bot that can access Twitch channels and listen for commands to schedule events. It will then provide chat reminders about upcoming events.",
        skills: "C++, SFML",
        link: "https://github.com/MJDoyle/SchedulerBot",
    },


]