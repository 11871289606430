import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

export const PublicationList = [

    {
        title: "Doyle, Matthew J., et al. \"Modular hydraulic propulsion: A robot that moves by routing fluid through itself.\" ", 
        location: "2016 IEEE International Conference on Robotics and Automation (ICRA). ",
        date: " IEEE, 2016.",
        link: "https://eprints.whiterose.ac.uk/108979/1/Modular%20Hydraulic%20Propulsion.pdf",
    },

    {
        title: "Escalera, Juan A., et al. \"Evo-bots: a simple, stochastic approach to self-assembling artificial organisms.\" ", 
        location: "Distributed Autonomous Robotic Systems: The 13th International Symposium. ", 
        date: "Springer International Publishing, 2018.",
        link: "https://drmatthewjdoyle.co.uk/static/media/DARS_2016_evo_bots.258c236599226fbfaf4a.pdf",
    },

    {
        title: "Marques, Joao V. Amorim, et al. \"Decentralized pose control of modular reconfigurable robots operating in liquid environments.\" ", 
        location: "2019 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS). ", 
        date: "IEEE, 2019.",
        link: "https://dspace.mit.edu/bitstream/handle/1721.1/137166/2019-iros-marques.pdf?sequence=2&isAllowed=y",
    },

    {
        title: "Doyle, Matthew J. ", 
        location: "The Propulsion of Reconfigurable Modular Robots in Fluidic Environments. ", 
        date: "Diss. University of Sheffield, 2019. ",
        link: "https://etheses.whiterose.ac.uk/27502/1/ThesisMJD.pdf",
    },

    {
        title: "Doyle, Matthew J., et al. \"Modular fluidic propulsion robots.\" ", 
        location: "IEEE transactions on robotics ", 
        date: "37.2 (2020): 532-549.",
        link: "https://ieeexplore.ieee.org/stamp/stamp.jsp?tp=&arnumber=9306917",
    },
]