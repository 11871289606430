import React from "react";
import { useParams } from "react-router-dom";
import { PersonalProjectList } from '../helpers/PersonalProjectList'
import "../styles/ProjectDisplay.css"
import { Link } from "react-router-dom"

function PersonalProjectDisplay() {
    const { id } = useParams();
    const project = PersonalProjectList[id];
    return <div className="project">
        <h1> {project.name}</h1>  

        <img src={project.image} alt="project"/>

        <div className="description">

            <p>{project.skills}</p>

            <p>{project.text}</p>   
        </div>

        <Link to={project.link} target="_blank">{project.link}</Link>


    </div>
}

export default PersonalProjectDisplay;