import Proj1 from '../assets/MFPRand.png'
import Proj2 from '../assets/evoModules.JPG' 

export const ProjectList = [

    {
        name: "Modular Fluidic Propulsion",
        image: Proj1,
        text: "MFP robots route fluid through themselves to self-propel in water or microgravity environments. They combine a large reconfiguration space with high scalability and effective propulsion. We applied a decentralized controller to the system which causes the system to move toward a target. My role in the project was to contribute to the development of the concept, create computer simulations of a 3D system, supervise the construction of a set of prototype modules, conduct experiments with those modules, and produce a mathematical proof to show that the system is guaranteed to reach a certain minimum distance from the target. ",
        links: ["https://eprints.whiterose.ac.uk/108979/1/Modular%20Hydraulic%20Propulsion.pdf", "https://ieeexplore.ieee.org/stamp/stamp.jsp?tp=&arnumber=9306917"]
    },

    {
        name: "Evo-bots",
        image: Proj2,
        text: "The Evo-bots are heterogenous, modular, self-reconfigurable robots that operate on a air-table, simulating a mcirogravity environment. Together, the modules form a robotic ecosystem, accreting to form larger structures that may survive or not in the environment depending upon their modular composition and the environmental parameters. My role in the project was to refine the design of the hardware modules, conduct experiments to validate the hardware and demonstrate the ability of the modules to form basic structures, and develop 2D simulations of the system with additional module types. ",
        links: ["https://drive.google.com/file/d/1-xffr-3ota0dBb5XCb0psvSAvuEWW1Mo/view"]
    },
]