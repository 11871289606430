import React from "react";
import '../styles/Contact.css'

function Contact() {
    return (
        <div class="contact">
            <h1>Contact</h1>

            <h2>Email:</h2>

            <p>drmatthewjdoyle@gmail.com</p>

            <h2>LinkedIn:</h2>

            <p>https://www.linkedin.com/in/matthew-j-doyle</p>

            <h2>GitHub:</h2>

            <p>https://github.com/MJDoyle</p>

        </div>
        
    );
}

export default Contact;