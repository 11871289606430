import React from "react";
import { Link } from "react-router-dom"
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import GitHubIcon  from "@material-ui/icons/GitHub";
import "../styles/Footer.css"

function Footer() {
    return <div className="footer">
        <div className="socialMedia">
        <Link to="https://www.linkedin.com/in/matthew-j-doyle" target="_blank"><LinkedInIcon/></Link>
        <EmailIcon style={{ cursor: 'pointer' }} onClick={event => window.location.href='mailto:drmatthewjdoyle@gmail.com'} />
        <Link to="https://github.com/MJDoyle" target="_blank"><GitHubIcon/></Link>
        </div>
        <p> &copy; 2023 drmatthewjdoyle.co.uk</p>
    </div>;
}

export default Footer;