import React from "react";
import '../styles/Projects.css'
import { PersonalProjectList } from '../helpers/PersonalProjectList'
import PersonalProjectItem from "../components/PersonalProjectItem";

function PersonalProjects() {
    return (
        <div className="projects">
            <h1> Coding Projects</h1>
            <div className="personalProjectList">
                {PersonalProjectList.map((project, index) => {
                    return <PersonalProjectItem id={index} name={project.name} image={project.image} />
                })}
            </div>
        </div>
    );
}

export default PersonalProjects;