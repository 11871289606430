import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css"
import SchoolIcon from "@material-ui/icons/School"
import WorkIcon from "@material-ui/icons/Work"
import CastForEducationIcon from "@material-ui/icons/CastForEducation"
import "../styles/Experience.css"

function Education() {
    return <div className="experience">
        <VerticalTimeline lineColor="#3e497a">
            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="2010 - 2014"
                iconStyle={{background: "#3e497a", color: "#fff" }}
                icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Oxford University</h3>
                    <p><i>MPhys</i> Master of Physics, with specialisation in astrophysics and condensed matter physics</p>

            </VerticalTimelineElement>
            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="2014 - 2020"
                iconStyle={{background: "#3e497a", color: "#fff" }}
                icon={<SchoolIcon />}
                >
                    <h3 className="vertical-timeline-element-title">The University of Sheffield</h3>
                    <p><i>PhD</i> in the Department of Control and Systems Engineering. Thesis title: The Propulsion of Reconfigurable Modular Robots in Fluidic Environments</p>

            </VerticalTimelineElement>
            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="2014 - 2018"
                iconStyle={{background: "#77b300", color: "#fff" }}
                icon={<CastForEducationIcon />}
                >
                    <h3 className="vertical-timeline-element-title">The University of Sheffield</h3>
                    <p>Graduate Training Assistant</p>

            </VerticalTimelineElement>
            <VerticalTimelineElement 
                className="vertical-timeline-element--education" 
                date="2020 - 2024"
                iconStyle={{background: "#e9d35b", color: "#fff" }}
                icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Freelance</h3>
                    <p>Line editor and proofreader</p>

            </VerticalTimelineElement>
        </VerticalTimeline>
        </div>
}

export default Education;