import React, { Component } from 'react';
import { useEffect } from'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Projects from './pages/Projects';
import PersonalProjects from './pages/PersonalProjects';
import Education from './pages/Education';
import Contact from './pages/Contact'
import MFP from './pages/MFP'
import Evobots from './pages/Evobots'
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import './App.css'
import ProjectDisplay from './pages/ProjectDisplay';
import PersonalProjectDisplay from './pages/PersonalProjectDisplay';

function App() {

  useEffect(() => {

    document.title = 'Dr. Matthew J. Doyle';

  }, []);

  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/MFP" element={<MFP />} />
          <Route path="/Evobots" element={<Evobots />} /> 
          <Route path="/projects" element={<Projects />} />
          <Route path="/project/:id" element={<ProjectDisplay />} />
          <Route path="/personalProjects" element={<PersonalProjects />} />
          <Route path="personalProject/:id" element ={<PersonalProjectDisplay />} />
          <Route path="/education" element={<Education />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );  
}

export default App;
