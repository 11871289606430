import React from "react";
import { useParams } from "react-router-dom";
import {ProjectList} from '../helpers/ProjectList'
import "../styles/ProjectDisplay.css"
import { Link } from "react-router-dom"

function ProjectDisplay() {
    const { id } = useParams();
    const project = ProjectList[id];
    return <div className="project">
        <h1> {project.name}</h1>  

        <img src={project.image} alt="project"/>
        <p>{project.text}</p>   
        {project.links.map((link, index) => {
            return (
                <Link to={link} target="_blank">{link}</Link>
            );
        })}  
    </div>
}

export default ProjectDisplay;