import React from "react";
import "../styles/MFP.css"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import EvobotFilm from "../assets/CHAIN6 (1).mp4"
import EvoHardware1 from "../assets/evoHardware1.png"
import EvoHardware2 from "../assets/evoHardware2.png"
import EvoConcept from "../assets/evoSoup(2).png"
import EvoEnvironment from "../assets/environmentPicture2-1.png"
import EvoStopping from "../assets/StoppingExperimentAlternate2-1.png"
import EvoDARS from "../assets/DARS_2016_evo_bots.pdf"
import { NavLink } from "react-router-dom"

function Evobots() {
    return (
        <div className="main">

            <div className="description">

                <h1>Evo-bots</h1>
                <video src={EvobotFilm} muted width="576" controls></video> 
                <p>The Evo-bots are a system of reconfigurable modules designed as an evolving robotic ecosystem. The modules do not self-propel, but move via external propulsion on an air table. Although the hardware implementation is heterogenous, each module can be programatically defined to fulfil a different role. For example, some modules can harvest and store energy using solar cells, while others can use a binary motion mechanism to switch between moving randomly and not moving. Upon colliding with one another, two modules may connect physically and electronically if they both permit it. Modules can communicate when connected, and can disconnect using an active connection mechanism.</p>
                <img src={EvoConcept} width="576" alt="The Evo-bot concept"/>
                <img src={EvoEnvironment} width="576" alt="The Evo-bot environment"/>
                <p>The Evo-bot modules can therefore connected to form structures, or robotic organisms. Depending on the environmental conditions (such as the presence of energy in the form of light) and its modular makeup, an organism might survive or die. If an organism dies its constituent modules will return to the soup to create new organisms. If an organism thrives, it will be able to create copies of itself, thereby perpetuating the life cycle.</p>
                <img src={EvoHardware1} width="576" alt="Evo-bot modules"/>
                <img src={EvoHardware2} width="576" alt="Evo-bot modules"/>
                <p>My contributions to the project were to refine the mechanical design of the modules and the operating environment of the evo-bots, and validate the system by conducting initial experiments in light detecting and motion control, energy harvesting and sharing, and structure formation. </p>
                <img src={EvoStopping} width="576" alt="A 2D MFP robot concept"/>
                <h2>Further information</h2>

                <ol className="list">

                        <li className="item">
                            <p>{"Escalera, Juan A., et al. \"Evo-bots: a simple, stochastic approach to self-assembling artificial organisms.\" "}<i>{"Distributed Autonomous Robotic Systems: The 13th International Symposium. "}</i>{"Springer International Publishing, 2018."}</p>
                            <a href = {EvoDARS} target="_blank" rel="noreferrer"><PictureAsPdfIcon/></a>
                        </li>

                        <li className="item">
                            <p>{"Doyle, Matthew J. "}<i>{"The Propulsion of Reconfigurable Modular Robots in Fluidic Environments. "}</i>{"Diss. University of Sheffield, 2019. "}</p>
                            <NavLink to="https://etheses.whiterose.ac.uk/27502/1/ThesisMJD.pdf" target="_blank"><PictureAsPdfIcon/></NavLink>
                        </li>

                </ol>
            </div>
        </div>
        
    );
}

export default Evobots;