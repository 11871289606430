import React from "react";
import MFPFilm from "../assets/MFP15sFilm.mov"
import MFPPrototypeIso from "../assets/moduleIso(1).JPG"
import MFPPrototypeOpen from "../assets/ModuleOpen(1).jpg"
import MFPConcept1 from "../assets/ModuleFacesImage-1.png"
import MFPConcept2 from "../assets/ConceptImage11-1-1.png"
import MFPSim1 from "../assets/randModSimPic(3).png"
import MFPResults1 from "../assets/thrust_fail_cm-1.png"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import "../styles/MFP.css"
import { NavLink } from "react-router-dom"


function MFP() {
    return (
        <div className="main">

            <div className="description">

                <h1>Modular Fluidic Propulsion</h1>
                <video src={MFPFilm} width="576" height ="324" controls></video> 
                <p>Modular Fluidic Propulsion (MFP) robots are modular, reconfigurable robots that combine a large reconfiguration space and scalable design with the ability to move effectively in any configuration. The combination of these properties allows the creation of robots that are robust to failure and capable of acting independently in unknown environments.  An MFP robot is a modular fluid network. By rearranging the constituent modules, robots of different shapes can be built. Each module contains an internal reservoir and a set of fluid pumps, one per face. Fluid is transfered between connected modules as the robot propels. MHP robots could be deployed as 2.5D or 3D systems, in aquatic or microgravity environments. </p>
                <img src={MFPConcept1} width="576" alt="A 2D MFP robot concept"/>
                <img src={MFPConcept2} width="576" alt="A 2D MFP robot concept"/>
                <p>To test the concept, we considered a directed motion task. Each module was equipped with a set of goal sensors, which could detect a point goal within the environment. A fully decentralized motion controller, running on each module face independently, was used to direct the robot toward the goal. The controller uses two bits of sensory information and requires neither run-time memory nor communication. Such a controller could be implemented on hardware that does not offer arithmetic computation, thereby engendering the future miniaturization of the system.</p>
                <img src={MFPPrototypeIso} width="576" alt="A 3D MFP robot prototype"/>
                <img src={MFPPrototypeOpen} width="576" alt="A 3D MFP robot prototype"/>            
                <p>My contributions to the project were to help develop the concept, generate 2D and 3D physics simulations of the system under various conditions, supervise the design and production of hardware prototype modules, and produce a formal description of the system, proving that an MFP robot is guaranteed to reach a certain minimum distance from the goal.</p>
                <img src={MFPSim1} width="576" alt="A simulated 3D MFP robot"/>
                <img src={MFPResults1} width="576" alt="Results of failure testing for a simulated MFP root"/>

                <h2>Further information</h2>

                <ol className="list">

                        <li className="item">
                            <p>{"Doyle, Matthew J., et al. \"Modular hydraulic propulsion: A robot that moves by routing fluid through itself.\" "}<i>{"2016 IEEE International Conference on Robotics and Automation (ICRA). "}</i>{" IEEE, 2016."}</p>
                            <NavLink to="https://eprints.whiterose.ac.uk/108979/1/Modular%20Hydraulic%20Propulsion.pdf" target="_blank"><PictureAsPdfIcon/></NavLink>
                        </li>

                        <li className="item">
                            <p>{"Marques, Joao V. Amorim, et al. \"Decentralized pose control of modular reconfigurable robots operating in liquid environments.\" "}<i>{"2019 IEEE/RSJ International Conference on Intelligent Robots and Systems (IROS). "}</i>{"IEEE, 2019."}</p>
                            <NavLink to="https://dspace.mit.edu/bitstream/handle/1721.1/137166/2019-iros-marques.pdf?sequence=2&isAllowed=y" target="_blank"><PictureAsPdfIcon/></NavLink>
                        </li>

                        <li className="item">
                            <p>{"Doyle, Matthew J., et al. \"Modular fluidic propulsion robots.\" "}<i>{"IEEE transactions on robotics "}</i>{"37.2 (2020): 532-549."}</p>
                            <NavLink to="https://ieeexplore.ieee.org/stamp/stamp.jsp?tp=&arnumber=9306917" target="_blank"><PictureAsPdfIcon/></NavLink>
                        </li>

                        <li className="item">
                            <p>{"Doyle, Matthew J. "}<i>{"The Propulsion of Reconfigurable Modular Robots in Fluidic Environments. "}</i>{"Diss. University of Sheffield, 2019. "}</p>
                            <NavLink to="https://etheses.whiterose.ac.uk/27502/1/ThesisMJD.pdf" target="_blank"><PictureAsPdfIcon/></NavLink>
                        </li>

                </ol>
            </div>
        </div>
        
    );
}

export default MFP;